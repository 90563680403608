import type { PromptStarter } from 'features/chat/types';

export const toolPromptStarters: Record<string, PromptStarter[]> = {
  'academic-content-generator': [
    {
      label: 'prompt_starter.academic-content-generator.warmup.label',
      promptText: 'prompt_starter.academic-content-generator.warmup.prompt',
    },
    {
      label: 'prompt_starter.academic-content-generator.vocabulary.label',
      promptText: 'prompt_starter.academic-content-generator.vocabulary.prompt',
    },
  ],
};
