import { List as ImmutableList, OrderedMap as ImmutableOrderedMap } from 'immutable';

export {
  List as ImmutableList,
  Map as ImmutableMap,
  Set as ImmutableSet,
  Repeat as ImmutableRepeat,
  Range as ImmutableRange,
  Collection as ImmutableCollection,
  isList,
} from 'immutable';

export const uniq = <T, K>(list: ImmutableList<T>, fn?: (item: T) => K): ImmutableList<T> => {
  return ImmutableOrderedMap(list.map((item) => [fn ? fn(item) : item, item])).toList();
};

export const compact = <T>(list: ImmutableList<T | undefined | null>) => {
  return list.filter(Boolean) as ImmutableList<T>;
};

export const partition = <T>(list: ImmutableList<T>, fn: (t: T) => unknown) => {
  return list.reduce<[ImmutableList<T>, ImmutableList<T>]>(
    ([left, right], value) => (fn(value) ? [left.push(value), right] : [left, right.push(value)]),
    [ImmutableList(), ImmutableList()],
  );
};
