import type { PublicSharedConfig } from '../types';

const BYTES_IN_KB = 1024;
const BYTES_IN_MB = BYTES_IN_KB * 1024;
const REQUEST_HEADERS_BUFFER = BYTES_IN_KB * 10;

export function createPublicSharedConfigFromEnv(): PublicSharedConfig {
  return {
    env: process.env.NODE_ENV,
    analytics: {
      googleAnalyticsId: process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID ?? '',
      googleTagManagerId: process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID ?? '',
      amplitudeApiKey: process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY ?? '',
    },
    supabase: {
      publicKey: process.env.NEXT_PUBLIC_SUPABASE_ANON_KEY ?? '',
      url: process.env.NEXT_PUBLIC_SUPABASE_URL ?? 'http://localhost:54321',
      fileSizeLimit: Number.parseInt(process.env.NEXT_PUBLIC_SUPABASE_FILE_SIZE_LIMIT_MB ?? '0') * BYTES_IN_MB + REQUEST_HEADERS_BUFFER,
    },
    googleOAuth: {
      clientId: process.env.NEXT_PUBLIC_GOOGLE_OAUTH_CLIENT_ID ?? '',
      clientSecret: process.env.NEXT_PUBLIC_GOOGLE_OAUTH_CLIENT_SECRET ?? '',
    },
    logging: {
      clientThreshold: process.env.NEXT_PUBLIC_LOGGING_CLIENT_THRESHOLD ?? '',
      serverThreshold: process.env.NEXT_PUBLIC_LOGGING_SERVER_THRESHOLD ?? '',
      infoPathOverride: process.env.NEXT_PUBLIC_LOGGING_INFO_PATH_OVERRIDE ?? '',
      debugPathOverride: process.env.NEXT_PUBLIC_LOGGING_DEBUG_PATH_OVERRIDE ?? '',
      sendErrorsToSentry: process.env.NEXT_PUBLIC_LOGGING_SEND_ERRORS_TO_SENTRY ?? '',
    },
    urls: {
      magicStudent: process.env.NEXT_PUBLIC_MAGIC_STUDENT_URL ?? '',
      magicSchool: process.env.NEXT_PUBLIC_MAGIC_SCHOOL_URL ?? '',
    },
  };
}
