export abstract class AuthorizationErrorBase extends Error {
  constructor(message: string) {
    super(message);
  }
}

export class AuthorizationError extends AuthorizationErrorBase {
  constructor(message: string) {
    super(message);
  }
}

export class StudentAuthorizationError extends AuthorizationErrorBase {
  constructor(message: string) {
    super(message);
  }
}
