import type { OrgSyncResponse } from '@/app/api/o/sync/route';
import type { OrgUsersResponse, UserWithOrg } from '@/app/api/o/users/route';
import type { OrgUsersSearchResponse } from '@/app/api/o/users/search/route';
import { syncOrg } from '@/features/orgSync/actions';
import { AuthorizationError } from '@/features/server/actions/authErrors';
import { renderDoWMDYT } from '@/util/date';
import type {
  OrgSyncDistrict,
  OrgSyncDistrictUpdated,
  OrgSyncPerson,
  OrgSyncPersonUpdated,
  OrgSyncSchool,
  OrgSyncSchoolUpdated,
} from '@magicschool/business-logic/edlink-sync';
import { logger } from '@magicschool/logger';
import type { Org, User, UserRole } from '@magicschool/supabase/types';
import type { MagicSchoolUser } from '@magicschool/supabase/user';
import { type SetField, createStoreSlice } from 'features/store/zustand';
import toast from 'react-hot-toast';
import type { IntlShape } from 'react-intl';

// for now this is identical to the admin users store
export const roles: NonNullable<User['user_role']>[] = ['teacher', 'org_admin'] as const;

export type UserRoleBadRequestTypes = 'different-organizations' | 'invalid-user-role';
export type SyncStatus = 'success' | 'error';

export type OrgUsersStore = {
  loading: boolean;
  loadingUsers: boolean;
  loadingSync: boolean;
  loadingRoleChange: boolean;
  searching: boolean;
  syncing: boolean;
  locale: string;
  org: Org | null;
  totalUsers: number | null;
  users: UserWithOrg[];
  usersAdded: OrgSyncPerson[];
  usersRemoved: OrgSyncPerson[];
  usersUpdated: OrgSyncPersonUpdated[];
  districtsAdded: OrgSyncDistrict[];
  districtsRemoved: OrgSyncDistrict[];
  districtsUpdated: OrgSyncDistrictUpdated[];
  schoolsAdded: OrgSyncSchool[];
  schoolsRemoved: OrgSyncSchool[];
  schoolsUpdated: OrgSyncSchoolUpdated[];
  searchQuery: string;
  searchResults: boolean;
  lastSynced: string;
  lastSyncHash: string | null;
  detailUser: User | null;
  syncInfoModalOpen: boolean;
  totalOrgUserCount: number;
  confirmSyncModalOpen: boolean;
  userDetailsModalOpen: boolean;
  removeOrgUserConfirmationModalOpen: boolean;
  load: (locale: string) => Promise<void>;
  loadUsers: () => Promise<void>;
  search: () => Promise<void>;
  openUserDetailsModal: (user: User) => void;
  handleReSync: (user: MagicSchoolUser) => Promise<[SyncStatus, string]>;
  setField: SetField<OrgUsersStore>;
  openRemoveOrgUserConfirmationModal: (user: User) => void;
  handleUserRoleUpdate: (role: UserRole, intl: IntlShape) => Promise<void>;
};

const defaultState = {
  loading: true,
  loadingUsers: true,
  loadingSync: false,
  loadingRoleChange: false,
  searching: false,
  syncing: false,
  locale: 'en-US',
  org: null,
  totalUsers: null,
  totalOrgUserCount: 0,
  users: [],
  usersAdded: [],
  usersRemoved: [],
  usersUpdated: [],
  districtsAdded: [],
  districtsRemoved: [],
  districtsUpdated: [],
  schoolsAdded: [],
  schoolsRemoved: [],
  schoolsUpdated: [],
  searchQuery: '',
  searchResults: false,
  lastSynced: '',
  lastSyncHash: '',
  detailUser: null,
  syncInfoModalOpen: false,
  confirmSyncModalOpen: false,
  userDetailsModalOpen: false,
  removeOrgUserConfirmationModalOpen: false,
};

export const createOrgUsersStoreSlice = createStoreSlice('OrgUsersStoreData', defaultState, ({ get, set, setField }) => ({
  setField,
  loadUsers: async () => {
    set({ loadingUsers: true });
    await fetch<OrgUsersResponse>('/api/o/users').then(async (response) => {
      const { users, org, totalOrgUserCount } = await response.json();
      set({ loadingUsers: false, users, org, totalUsers: users.length, totalOrgUserCount }); //total up to 1000
    });
  },
  load: async (locale: string) => {
    set({ ...defaultState, loading: true, locale });
    await fetch<OrgSyncResponse>('/api/o/sync?mode=summary', {
      method: 'GET',
      onSuccess: async ({ response }) => {
        const { orgSyncId, status, finish_date } = await response.json();
        const syncing = status ? status === 'syncing' : false;
        const lastSynced = finish_date ? renderDoWMDYT(locale, finish_date) : '';
        set({ loading: false, syncing, lastSynced, lastSyncHash: orgSyncId });
      },
      responseErrorHandlers: {
        notFound: () => ({ shortCircuit: true }),
        forbidden: () => ({ shortCircuit: true }),
        unauthorized: () => ({ shortCircuit: true }),
      },
    });
  },
  search: async () => {
    const { searchQuery } = get();
    if (searchQuery.length < 3) return;
    set({ searching: true, users: [], searchResults: true });

    const response = await fetch<OrgUsersSearchResponse>(`/api/o/users/search?query=${searchQuery}`);
    const { users } = await response.json();

    set({ searching: false, users });
  },
  openUserDetailsModal: (user: UserWithOrg) => {
    set({ detailUser: user, userDetailsModalOpen: true });
  },
  openRemoveOrgUserConfirmationModal: (user) => {
    set({ detailUser: user, removeOrgUserConfirmationModalOpen: true });
  },
  handleReSync: async () => {
    const { org } = get();
    if (org?.id) {
      set({ syncing: true });

      const triggerId = await syncOrg(org.id);
      if (triggerId && !(triggerId instanceof AuthorizationError)) {
        logger.info(`Sync triggered by org ${org.id} successfully (${triggerId.data})`);
        return ['success', 'org.users.re-sync.syncing-success'];
      } else {
        set({ syncing: false });
        logger.error(`Failed to trigger sync for org ${org.id}`, { error: triggerId });
        return ['error', 'org.users.re-sync.syncing-error'];
      }
    }
    return ['error', 'org.users.re-sync.syncing-error'];
  },
  handleUserRoleUpdate: async (user_role: UserRole, intl: IntlShape) => {
    const detailUser = get().detailUser;
    if (detailUser) {
      await fetch(`/api/o/users/user_role/${detailUser.id}`, {
        method: 'PUT',
        body: JSON.stringify({ user_role }),
        responseErrorHandlers: {
          notFound: () => {
            toast.error(intl.formatMessage({ id: 'org.users.user-profile-modal.edit-role.toast.user-not-found' }));
          },
          badRequest: async ({ response }) => {
            let responseBody = await response.json<{ error: UserRoleBadRequestTypes }>();
            let error: string;

            if (responseBody.error === 'different-organizations') {
              error = intl.formatMessage({ id: 'org.users.user-profile-modal.edit-role.toast.different-orgs' });
            } else if (responseBody.error === 'invalid-user-role') {
              error = intl.formatMessage({ id: 'org.users.user-profile-modal.edit-role.toast.invalid-user-roles' });
            } else {
              error = responseBody.error;
            }
            toast.error(error);
          },
          unauthorized: () => {
            toast.error(intl.formatMessage({ id: 'org.users.user-profile-modal.edit-role.toast.unauthorized' }));
            return { shortCircuit: true }; // Don't fire pre-configured error handlers (e.g. toast errors, redirects)
          },
        },
        onSuccess: () => {
          get().loadUsers();
          toast.success(intl.formatMessage({ id: 'org.users.user-profile-modal.edit-role.toast.success' }));
        },
      });
    }
  },
}));
