import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import type { List } from 'immutable';
import { FormattedMessage } from 'react-intl';
export type ChipMultiselectProps = {
  options: string[];
  selectedOptions: List<string>;
  setSelectedOptions: (options: List<string>) => void;
  justify?: 'start' | 'center' | 'end';
  intlId?: (option: string) => string;
};
export const ChipMultiselect = ({
  options,
  selectedOptions,
  setSelectedOptions,
  justify = 'center',
  intlId
}: ChipMultiselectProps) => {
  const handleSelect = (option: string, isSelected: boolean) => {
    if (isSelected) {
      setSelectedOptions(selectedOptions.filter(selectedOption => selectedOption !== option));
    } else {
      setSelectedOptions(selectedOptions.push(option));
    }
  };
  return <Grid container spacing={1.5} justifyContent={justify} data-sentry-element="Grid" data-sentry-component="ChipMultiselect" data-sentry-source-file="ChipMultiselect.tsx">
      {options.map(option => {
      const isSelected = selectedOptions.includes(option);
      return <Grid item key={option} zeroMinWidth>
            <Chip component="button" label={<FormattedMessage id={intlId ? intlId(option) : option} />} variant={isSelected ? 'filled' : 'outlined'} onClick={() => handleSelect(option, isSelected)} sx={{
          fontSize: '12px'
        }} />
          </Grid>;
    })}
    </Grid>;
};