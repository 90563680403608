import { ImmutableMap } from '@/util/immutable';
import type { ModerationCategory } from 'features/moderation/constants';
import { createStoreSlice } from 'features/store/zustand';
import toast from 'react-hot-toast';

export type AdminModerationSettingsStore = {
  hasBeenUpdated: boolean;
  saving: boolean;
  modalOpen: boolean;
  originalModerationModifiers: Record<ModerationCategory, number>;
  moderationModifiers: Record<ModerationCategory, number>;
  hydrate: (moderationModifiers: Record<ModerationCategory, number>) => void;
  getModifiersByCategory: (category: string) => Record<string, number>;
  updateModerationProperty: (property: ModerationCategory, value: number) => void;
  saveModerationSettings: (saveMethod: (modifiers: Record<ModerationCategory, number>) => Promise<void>) => Promise<void>;
  toggleModal: (value: boolean) => void;
  resetToBaseline: () => void;
  reset: () => void;
};

const defaultModerationModifierMap = (): Record<ModerationCategory, number> => ({
  harassment: 1,
  'harassment/threatening': 1,
  hate: 1,
  'hate/threatening': 1,
  sexual: 1,
  'sexual/minors': 1,
  'self-harm': 1,
  'self-harm/instructions': 1,
  'self-harm/intent': 1,
  violence: 1,
  'violence/graphic': 1,
});

const defaultState = {
  modalOpen: false,
  saving: false,
  hasBeenUpdated: false,
  testPrompt: '',
  originalModerationModifiers: defaultModerationModifierMap(),
  moderationModifiers: defaultModerationModifierMap(),
};

export const createAdminModerationSettingsStoreSlice = createStoreSlice(
  'AdminModerationSettingsStoreData',
  defaultState,
  ({ set, get }) => ({
    hydrate: (moderationModifiers) => {
      set({ moderationModifiers, originalModerationModifiers: moderationModifiers });
    },
    toggleModal: (value: boolean) => {
      set({ modalOpen: value });
    },
    updateModerationProperty: (property, value) => {
      set((state) => {
        return {
          moderationModifiers: {
            ...state.moderationModifiers,
            [property]: value,
          },
        };
      });

      set({ hasBeenUpdated: true });
    },
    getModifiersByCategory: (category) => {
      const { moderationModifiers } = get();
      const keys = ImmutableMap(moderationModifiers).keys();
      const modifiers: Record<string, number> = {};
      for (const key of keys) {
        if (key.startsWith(category)) {
          const [keyCategory, property] = key.split('/');
          if (!property) {
            modifiers[keyCategory] = moderationModifiers[key];
          } else {
            modifiers[property] = moderationModifiers[key];
          }
        }
      }
      return modifiers;
    },
    saveModerationSettings: async (saveMethod) => {
      set({ saving: true });
      try {
        const { moderationModifiers } = get();
        await saveMethod(moderationModifiers);
        set((state) => ({ hasBeenUpdated: false, saving: false, originalModerationModifiers: state.moderationModifiers }));
      } catch (error) {
        console.error(error);
        toast.error('Failed to save moderation settings');
        set({ saving: false });
      }
    },
    resetToBaseline: () => {
      const defaults = defaultModerationModifierMap();
      set((state) => ({
        moderationModifiers: defaults,
        hasBeenUpdated: JSON.stringify(defaults) !== JSON.stringify(state.originalModerationModifiers),
      }));
    },
    reset: () => {
      set((state) => ({ moderationModifiers: state.originalModerationModifiers, hasBeenUpdated: false }));
    },
  }),
);
