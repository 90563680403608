import type { Language } from '@magicschool/supabase/types';
import { type ImageLike, type Scheduler, createScheduler, createWorker } from 'tesseract.js';

const LOCALE_TO_TESSERACT_LANG: Record<Language, string> = {
  ar: 'ara',
  de: 'deu',
  'en-us': 'eng',
  'en-gb': 'eng',
  es: 'spa',
  fr: 'fra',
  'fr-ca': 'fra',
  id: 'ind',
  it: 'ita',
  he: 'heb',
  hi: 'hin',
  ja: 'jpn',
  'zh-cn': 'chi_sim',
  'zh-hk': 'chi_tra',
};

function toTesseractLang(language: Language) {
  return LOCALE_TO_TESSERACT_LANG[language];
}

class ImageTextExtractorPipeline {
  private scheduler: Scheduler;
  private language = '';
  private numberOfWorkers = 0;

  constructor() {
    this.scheduler = createScheduler();
  }

  async initialize(language: Language, workerCount: number = navigator.hardwareConcurrency) {
    const newLang = toTesseractLang(language);

    // If nothing changed, return
    if (newLang === this.language && workerCount === this.numberOfWorkers) return;

    // If language is the same && more workers needed, create them
    if (newLang === this.language && workerCount > this.numberOfWorkers) {
      return await this.createWorkers(workerCount - this.numberOfWorkers);
    }

    // If the language changed, we need to terminate the scheduler and create new workers
    this.language = newLang;
    // get a new scheduler
    await this.cleanup();
    this.scheduler = createScheduler();
    // create workers
    await this.createWorkers(workerCount);
  }

  async createWorkers(workerCount: number = navigator.hardwareConcurrency) {
    this.scheduler = createScheduler();
    for (let i = 0; i < workerCount; i++) {
      const worker = await createWorker(this.language);
      this.scheduler.addWorker(worker);
      this.numberOfWorkers++;
    }
  }

  async extract(image: ImageLike) {
    const res = await this.scheduler.addJob('recognize', image);
    return res.data.text;
  }

  async extractArray(images: ImageLike[]) {
    return Promise.all(images.map((i) => this.extract(i)));
  }

  async cleanup() {
    await this.scheduler.terminate();
  }
}

// export global singleton to avoid worker spin up time (only first usage will be slow)
export const imageTextExtractor = new ImageTextExtractorPipeline();
