'use client';

import type { UpdateUser } from '@magicschool/supabase/types';
import type { User } from '@magicschool/supabase/user';
import { dataOnSuccess } from '@magicschool/supabase/utils';
import { client, queryFactory, useQuery } from 'hooks/useQuery';
import omit from 'lodash-es/omit';
import { clientSupabase } from './supabase';

export function useOrgDetails(org_id: string | undefined | null) {
  const orgArg = org_id ?? '';
  return useQuery({
    queryKey: queryFactory.useOrgDetails(orgArg),
    queryFn: async () => await clientSupabase.from('orgs').select(`*`).eq('id', orgArg).single().then(dataOnSuccess),
    enabled: !!org_id,
  });
}

export async function updateUserProfile(uid: string, data: UpdateUser) {
  const updatableUserFields = omit(data, 'id', 'email', 'plan', 'org_id', 'status');
  const response = await clientSupabase.from('users').update(updatableUserFields).eq('id', uid).select(`*`).single().then(dataOnSuccess);
  // Invalidate and refetch queries that could have old data
  await client.invalidateQueries({ queryKey: queryFactory.useUser(uid) });
  return response;
}

export async function deleteUser(userId: User['id']) {
  const response = await fetch('/api/profile/delete_user', { method: 'POST' });
  try {
    await client.invalidateQueries({ queryKey: queryFactory.useUser(userId ?? '') });
  } catch (_) {
    /* silently ignore */
  }

  return response;
}

export function useUserFavoritesOrdered(uid: string) {
  return useQuery({
    queryKey: queryFactory.userFavoritesOrdered(uid),
    queryFn: async () =>
      await clientSupabase.from('user_favorites_ordered').select(`*`).eq('user_id', uid).maybeSingle().then(dataOnSuccess),
    enabled: !!uid,
  });
}

export async function updateUserFavorites(uid: string, tools: string[]) {
  const data = { user_id: uid, tools: tools };
  const response = await clientSupabase.from('user_favorites_ordered').upsert(data, { onConflict: 'user_id' }).then(dataOnSuccess);

  // Invalidate and refetch queries that could have old data
  await client.invalidateQueries({ queryKey: queryFactory.userFavoritesOrdered(uid) });
  return response;
}
