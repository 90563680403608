import { roles } from '@/features/profile/constants';
import { useStore } from '@/features/store';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { type SelectChangeEvent } from '@mui/material/Select';
import { FormattedMessage, useIntl } from 'react-intl';
export const Role = () => {
  const {
    selectedRole,
    setField
  } = useStore(({
    ProfileBuilderStoreData: s
  }) => ({
    selectedRole: s.selectedRole,
    setField: s.setField
  }), []);
  const intl = useIntl();
  const handleRoleChange = (event: SelectChangeEvent) => {
    setField('selectedRole')(event.target.value);
  };
  return <FormControl sx={{
    width: 320
  }} data-sentry-element="FormControl" data-sentry-component="Role" data-sentry-source-file="Role.tsx">
      <Select labelId="demo-simple-select-label" id="demo-simple-select" value={selectedRole} label={<FormattedMessage id="profile.fields.role" />} onChange={handleRoleChange} data-test-id="role-select" data-sentry-element="Select" data-sentry-source-file="Role.tsx">
        {roles.map(role => <MenuItem key={role.id} value={role.id} data-test-id={role.id}>
            {intl.formatMessage({
          id: `role.${role.id}`
        })}
          </MenuItem>)}
      </Select>
      <InputLabel data-sentry-element="InputLabel" data-sentry-source-file="Role.tsx">
        <FormattedMessage id="profile.fields.role" data-sentry-element="FormattedMessage" data-sentry-source-file="Role.tsx" />
      </InputLabel>
    </FormControl>;
};