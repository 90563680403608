import { logger } from '@magicschool/logger';
import type { MessageModerationData, RoomState } from '@magicschool/supabase/types';
import { clientSupabase } from 'util/supabase';
import { events, channels } from './channels';

export type NewToolChatMessage = {
  roomStudentId: string;
  tool_id: string;
  tool_uuid: string;
  message: string;
};

export const broadcastNewToolChat = (roomId: string, roomStudentId: string, tool_id: string, tool_uuid: string, message: string) => {
  const payload: NewToolChatMessage = { roomStudentId, tool_id, tool_uuid, message };
  generalBroadcast(roomId, 'teacherRoomChannel', 'toolChat', payload);
};

export const broadcastFlaggedMessage = (roomId: string, moderation: MessageModerationData) => {
  generalBroadcast(roomId, 'teacherRoomChannel', 'flaggedMessage', moderation);
};

export type RoomStateChangeMessage = { state: `${RoomState}` };
export const broadcastRoomStateChange = (roomId: string, state: `${RoomState}`) => {
  const payload: RoomStateChangeMessage = { state };
  generalBroadcast(roomId, 'studentRoomChannel', 'roomState', payload);
};

export type RoomStudentStateChangeMessage = {
  state: `${RoomState}`;
  roomStudentId: string;
};

export const broadcastRoomStudentStateChange = (roomId: string, roomStudentId: string, state: `${RoomState}`) => {
  const payload: RoomStudentStateChangeMessage = { state, roomStudentId };
  generalBroadcast(roomId, 'studentRoomChannel', 'roomStudentState', payload);
};

export type RoomStudentJoinedMessage = { roomStudentId: string };
export const broadcastStudentJoinedRoom = (roomId: string, roomStudentId: string) => {
  const payload: RoomStudentJoinedMessage = { roomStudentId };
  generalBroadcast(roomId, 'teacherRoomChannel', 'roomStudentJoined', payload);
};

export type MessagePayload<T> = {
  payload: T;
};

const generalBroadcast = <T>(roomId: string, channelName: keyof typeof channels, event: keyof typeof events, payload: T) => {
  // There's a feature that's not yet available on local to use rest apis
  // instead of web sockets, so we're using the web sockets for now.
  logger.info(`broadcasting ${events[event]}`, roomId, payload);
  const tempChannel = clientSupabase.channel(channels[channelName](roomId));
  tempChannel.subscribe(async (status) => {
    if (status !== 'SUBSCRIBED') return;
    const typedPayload: MessagePayload<T> = { payload };
    await tempChannel.send({
      type: 'broadcast',
      event: events[event],
      payload: typedPayload,
    });
    tempChannel.unsubscribe();
  });
};

// I cannot explain how this is happening, but supabase is sometimes
// wrapping payload in an extra payload object, and sometimes not...
// ... It makes me scare too.
export function handleIntermittentPayloadIssue<T>(payload: T) {
  const internalPayload = payload as any;
  if (internalPayload.payload) {
    return internalPayload.payload as T;
  }
  return payload;
}
