'use client';

import { PageLoader } from '@magicschool/loader/PageLoader';
import { registerMonkeyFetch } from '@magicschool/monkey-fetch';
import { handleSignOut } from 'features/auth/helpers';
import { useRouter } from 'next/navigation';
import type { ReactNode } from 'react';
import { createContext, useRef } from 'react';
import toast from 'react-hot-toast';
import { useIntl } from 'react-intl';
import type { StoreApi } from 'zustand';
import { type FullState, type InitialStateProps, createInitialState } from './store';
import { createStrictStore } from './zustand';
export const ZustandContext = createContext<StoreApi<FullState> | null>(null);
interface ZustandProviderProps {
  initialData?: InitialStateProps;
  children: ReactNode;
}
export const ZustandProvider = ({
  children,
  initialData = {}
}: ZustandProviderProps) => {
  const router = useRouter();
  const storeRef = useRef<StoreApi<FullState>>();
  const intl = useIntl();
  if (!storeRef.current) {
    registerMonkeyFetch(router, [{
      matcher: [/^\/api\/.*/, new RegExp(`^${window?.location.origin}/api/.*`)],
      requestInterceptor: (url, options) => {
        const headers = new Headers({
          ...options.headers
        });
        if (options.body && !headers.get('Content-Type') && !(options.body instanceof FormData)) {
          headers.set('Content-Type', 'application/json');
        }
        return {
          url,
          options: {
            ...options,
            credentials: 'include',
            mode: 'cors',
            headers
          }
        };
      },
      responseErrorHandlers: {
        conflict: () => {
          toast.error(intl.formatMessage({
            id: 'toast.conflict'
          }));
        },
        badRequest: () => {
          toast.error(intl.formatMessage({
            id: 'toast.bad-request'
          }));
        },
        unauthorized: () => {
          toast.error(intl.formatMessage({
            id: 'toast.no-access'
          }));
          console.warn("I'm sorry, Dave. I'm afraid I can't do that.");
          router.push('/');
        },
        forbidden: () => {
          toast.error(intl.formatMessage({
            id: 'toast.expired-session'
          }));
          handleSignOut();
        },
        unknown: () => {
          toast.error(intl.formatMessage({
            id: 'toast.unknown-error'
          }));
        }
      }
    }]);
    storeRef.current = createStrictStore(createInitialState(initialData));
  }
  if (!storeRef.current) {
    return <PageLoader />;
  }
  return <ZustandContext.Provider value={storeRef.current} data-sentry-element="unknown" data-sentry-component="ZustandProvider" data-sentry-source-file="contextClient.tsx">{children}</ZustandContext.Provider>;
};