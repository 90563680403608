import { MILLISECONDS_IN_DAY } from 'util/date';

const signupTrialDays = 14;
const signupTrialStart = '2024-01-02';
const defaultCreatedAt = '1970-01-01T00:00:00Z';

export function signupTrialAppliesToUser(user_created_at: string | null) {
  if (!user_created_at) return false;
  return new Date(user_created_at) >= new Date(signupTrialStart) && getTrialDaysLeft(user_created_at) > 0;
}

export function getTrialDaysLeft(user_created_at: string | null) {
  if (new Date(user_created_at ?? defaultCreatedAt) < new Date(signupTrialStart)) return 0;
  const trialEnd = new Date(user_created_at ?? defaultCreatedAt);
  trialEnd.setDate(trialEnd.getDate() + signupTrialDays);
  const now = new Date();
  const daysLeft = Math.ceil((trialEnd.getTime() - now.getTime()) / MILLISECONDS_IN_DAY);
  return daysLeft;
}
