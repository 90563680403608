'use client';

import { useStore } from '@/features/store';

export const useSiteConfig = () => {
  const { siteConfigs } = useStore(
    ({ SiteConfigStoreData: scs }) => ({
      ...scs,
    }),
    [],
  );

  return {
    ...siteConfigs,
  };
};
