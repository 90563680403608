'use client';

import { useCallback, useEffect, useState } from 'react';

// This hook is used to progressively read the output of a ReadableStream,
// e.g. it is used in the Raina page to render the output of the chatbot as it is generated
//
// readStream      - jumpstarts the reading sequence. Every new chunk triggers a re-render
// content         - contains an updated version of the stream output as it is read
export function useReadStream(): [(stream: ReadableStream) => Promise<string>, string, boolean, () => void] {
  const [loading, setLoading] = useState(false);
  const [content, setContent] = useState('');

  const reset = useCallback(() => {
    setContent('');
    setLoading(false);
  }, [setContent, setLoading]);

  // reset needs to run on a useEffect to give time for the content to be rendered
  useEffect(() => {
    if (!loading) {
      reset();
    }
  }, [loading, reset]);

  const readStream = async (stream: ReadableStream) => {
    const reader = stream.getReader();
    const decoder = new TextDecoder();
    let streamFinished = false;
    let output = '';
    setLoading(true);
    setContent('');
    while (!streamFinished) {
      const { value: chunk, done } = await reader.read();
      const decodedChunk = decoder.decode(chunk);
      setContent((content) => content + decodedChunk);
      output += decodedChunk;
      streamFinished = done;
    }
    setLoading(false);
    return output;
  };

  return [readStream, content, loading, reset];
}
