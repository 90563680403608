import { clientSupabase } from '@/util/supabase';
import { logger } from '@magicschool/logger';
import type { AuthProviderType } from '@magicschool/supabase/types';
import { getStudentUrl } from '@magicschool/utils/nextjs/url';
import { type SetField, createStoreSlice } from 'features/store/zustand';
import type { AppRouterInstance } from 'next/dist/shared/lib/app-router-context.shared-runtime';
import { checkEmail } from './actions';
import { allProviders, supportsEmailProvider } from './constants';

type FormAlert = {
  type: 'success' | 'error' | 'analytics';
  messageId?: string;
  message?: string;
  duration?: number;
  page:
    | 'SignIn:Error'
    | 'SignIn:Success'
    | 'SignUp:Error'
    | 'SignUp:StudentError'
    | 'SignUp:SSOStudentError'
    | 'SignUp:Success'
    | 'User:Deleted'
    | 'ResetPassword:Error'
    | 'ResetPassword:Success'
    | 'UpdatePassword:Error'
    | 'UpdatePassword:Success';
};

export type AuthFormStore = {
  setField: SetField<AuthFormStore>;
  loading: boolean;
  email: string;
  emailChecked: boolean;
  roleSelected: boolean;
  formAlert: FormAlert | null;
  authProviders: AuthProviderType[];
  checkEmail: (email: string, page: 'signin' | 'signup') => Promise<void>;
  reset: () => void;
  signIn: (email: string, password: string, next: string) => Promise<void>;
  signUp: (email: string, password: string, router: AppRouterInstance) => Promise<void>;
  resetPassword: (email: string) => Promise<void>;
  updatePassword: (password: string, router: AppRouterInstance) => Promise<void>;
};

const defaultState = {
  loading: false,
  formAlert: null,
  authProviders: [...allProviders],
  emailChecked: false,
  roleSelected: false,
  email: '',
};

export const ERROR_STUDENT_ACCOUNT_ALREADY_EXISTS = 'Student Account Already Exists';

export const createAuthFormStoreSlice = createStoreSlice(
  'AuthFormStoreData',
  structuredClone(defaultState),
  ({ getFull, set, setField }) => ({
    setField,
    reset: () => set(structuredClone(defaultState)),
    checkEmail: async (email, page) => {
      set({ loading: true });
      const result = await checkEmail(email, page);
      if (!result) return;
      set({
        email,
        authProviders: result.authProviders,
        emailChecked: !result.error,
        formAlert: result.error
          ? { type: 'error', messageId: result.error, page: page === 'signin' ? 'SignIn:Error' : 'SignUp:Error' }
          : null,
        loading: false,
      });
    },
    signIn: async (email, password, next) => {
      set({ loading: true, formAlert: null });
      try {
        const result = await getFull().AuthStoreData.signin(email, password, next);
        if (result?.error) {
          set({ loading: false, formAlert: { type: 'error', message: result.error.message, page: 'SignIn:Error' } });
          return;
        } else {
          set({ loading: false, formAlert: { type: 'analytics', message: 'Success', page: 'SignIn:Success' } });
          return;
        }
      } catch (err) {
        logger.error('Error signing in', err);
        set({ loading: false, formAlert: { type: 'error', message: (err as Error).message, page: 'SignIn:Error' } });
      }
    },
    signUp: async (email, password, router) => {
      set({ loading: true });
      try {
        await getFull().AuthStoreData.signup(email, password);
        router.push('/auth/signup-success');
      } catch (e) {
        if ((e as Error).message === ERROR_STUDENT_ACCOUNT_ALREADY_EXISTS) {
          set({ formAlert: { type: 'error', messageId: 'auth.existing-student-account', page: 'SignUp:StudentError', duration: 5000 } });
          setTimeout(() => {
            router.push(getStudentUrl());
          }, 5000); // this is to give the person a chance to read the error before forwarding
          logger.warn(`Student attempted to create a teacher account ${email}`);
        } else {
          set({ formAlert: { type: 'error', messageId: 'toast.generic-error', page: 'SignUp:Error' } });
          logger.error('Error signing up', e);
        }
      } finally {
        set({ loading: false });
      }
    },
    resetPassword: async (email) => {
      set({ loading: true });
      try {
        const result = await checkEmail(email, 'password-reset');
        if (!supportsEmailProvider(result.authProviders)) {
          set({ formAlert: { type: 'success', messageId: 'auth.password-reset-message', page: 'ResetPassword:Success' } });
          return;
        }

        await getFull().AuthStoreData.sendPasswordResetEmail(email);
        set({ formAlert: { type: 'success', messageId: 'auth.password-reset-message', page: 'ResetPassword:Success' } });
      } catch (err) {
        logger.error('Error sending password reset', err);
        set({ formAlert: { type: 'error', messageId: 'toast.generic-error', page: 'ResetPassword:Error' } });
      } finally {
        set({ loading: false });
      }
    },
    updatePassword: async (password, router) => {
      set({ loading: true });
      const error = await getFull().AuthStoreData.updatePassword(password);
      if (error) {
        set({ loading: false, formAlert: { type: 'error', message: error.message, page: 'UpdatePassword:Error' } });
        return;
      }

      // Need to sign out after password reset
      const { error: signoutError } = await clientSupabase.auth.signOut();
      if (signoutError) {
        set({ loading: false });
        return;
      }

      router.push('/auth/signin');
      set({ loading: false, formAlert: { type: 'success', messageId: 'auth.password-changed-message', page: 'UpdatePassword:Success' } });
    },
  }),
);
