import type { MaintenanceRaw, MaintenanceWindow, SiteConfigs, TosUpdateWindow, TosUpdateWindowRaw } from './types';

const MILLISECONDS_IN_HOUR = 60 * 60 * 1000;

export const defaultSiteConfigs: SiteConfigs = {
  featureFlags: {
    disabledSubscriptions: false,
    magicStudentEnabled: false,
    pdfUploadEnabled: false,
    adobeEnabled: false,
    youtubeImportEnabled: false,
    websiteImportEnabled: false,
    extractTextUsingOpenAI: false,
  },
  maintenance: null,
  tosUpdateWindows: [],
  systemMessages: {
    globalStudentToolSystemMessage: '',
    globalTeacherToolSystemMessage: '',
  },
};

export function convertMaintenanceValuesIntoDates(maintenance: MaintenanceRaw): SiteConfigs['maintenance'] {
  const maintenanceStartDateTime = new Date(maintenance.startDateTime);
  const maintenanceEndDateTime = maintenance.endDateTime ? new Date(maintenance.endDateTime) : null;
  const hoursToWarnBefore = maintenance.hoursToWarnBefore ? Number.parseInt(maintenance.hoursToWarnBefore.toString(), 10) : null;

  const maintenanceWindow: MaintenanceWindow = {
    startDateTime: maintenanceStartDateTime,
    mode: 'serviceInterruption',
    labelId: maintenance.labelId ? maintenance.labelId : null,
    borkedFunction: maintenance.borkedFunction ? maintenance.borkedFunction : null,
  };

  if (maintenanceEndDateTime || hoursToWarnBefore) {
    maintenanceWindow.mode = 'siteMaintenance';
    if (maintenanceEndDateTime) {
      maintenanceWindow.endDateTime = maintenanceEndDateTime;
    }
    if (hoursToWarnBefore) {
      maintenanceWindow.showWarningBannerDateTime = new Date(maintenanceStartDateTime.getTime() - hoursToWarnBefore * MILLISECONDS_IN_HOUR);
    }
  }

  return maintenanceWindow;
}

export function convertTosWindow(key: string, window: TosUpdateWindowRaw): TosUpdateWindow {
  return {
    windowKey: key,
    startDateTime: new Date(window.startDateTime),
    endDateTime: new Date(window.endDateTime),
    messageId: window.messageId ? window.messageId : 'tos.defaultMessageId',
  };
}
