import CircularProgress, { type CircularProgressProps } from '@mui/material/CircularProgress';
import { styled } from '@mui/material/styles';
import { Button, type ButtonProps, type ButtonSize } from '../Button';
type IconCssProps = {
  height: number;
  width: number;
  marginRight: number;
};
const getIconSize = (size: ButtonSize): IconCssProps => {
  switch (size) {
    case 'xsmall':
      return {
        height: 16,
        width: 16,
        marginRight: 6
      };
    case 'small':
      return {
        height: 20,
        width: 20,
        marginRight: 8
      };
    case 'medium':
      return {
        height: 22,
        width: 22,
        marginRight: 12
      };
    case 'large':
      return {
        height: 24,
        width: 24,
        marginRight: 16
      };
    case 'xlarge':
      return {
        height: 26,
        width: 26,
        marginRight: 16
      };
  }
};
type StyledCircularProgressProps = CircularProgressProps & {
  size: ButtonSize;
};
const StyledCircularProgress = styled(CircularProgress)((props: StyledCircularProgressProps) => {
  const size = getIconSize(props.size);
  return `
    width: ${size.width}px !important;
    height: ${size.height}px !important;
    margin-right: ${size.marginRight}px;
  `;
});
export type LoadingButtonProps = ButtonProps & {
  /**
   * The loading state of the button
   * @default false
   */
  loading: boolean;

  /**
   * The content of the button will be hidden when loading is true
   * @default false
   */
  hideContentOnLoad?: boolean;
};
export const LoadingButton = (props: LoadingButtonProps) => {
  // grab loading from props so that ...rest is actually ButtonProps
  const {
    loading,
    hideContentOnLoad,
    ...rest
  } = props;
  const size = props.size ?? 'medium';
  return <Button {...rest} data-sentry-element="Button" data-sentry-component="LoadingButton" data-sentry-source-file="LoadingButton.tsx">
      {loading && <StyledCircularProgress color="inherit" size={size} />}
      {hideContentOnLoad && loading ? null : props.children}
    </Button>;
};