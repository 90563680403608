import type { OrgDashboardResponse } from 'app/api/o/dashboard/route';
import { type SetField, createStoreSlice } from 'features/store/zustand';

const defaultOrgDashboard = {
  name: '',
  id: '',
  logoUrl: '',
  iframeUrl: '',
};

const defaultState = {
  loading: true,
  orgData: defaultOrgDashboard,
  reportingConfirmationModalOpen: true,
};

export type OrgDashboardStore = {
  loading: boolean;
  orgData: OrgDashboardResponse;
  reportingConfirmationModalOpen: boolean;
  setField: SetField<OrgDashboardStore>;
  loadCurrentUserOrg: () => Promise<void>;
  loadOrg: (orgId: string) => Promise<void>;
};

export const createOrgDashboardStoreSlice = createStoreSlice('OrgDashboardStoreData', defaultState, ({ set, setField }) => ({
  setField,
  loadCurrentUserOrg: async () => {
    set({ loading: true, orgData: defaultOrgDashboard });
    const response = await fetch<OrgDashboardResponse>(`/api/o/dashboard`);
    const data = await response.json();
    set({ orgData: data, loading: false });
  },
  loadOrg: async (orgId: string) => {
    set({ reportingConfirmationModalOpen: true, loading: true, orgData: defaultOrgDashboard });
    const response = await fetch<OrgDashboardResponse>(`/api/admin/orgs/${orgId}/reporting`);
    const data = await response.json();
    set({ orgData: data, loading: false });
  },
}));
