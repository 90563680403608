import type { SiteConfigValue } from '@magicschool/business-logic/site-configs';
import { type SetField, createStoreSlice } from 'features/store/zustand';

export type SystemMessageStore = {
  setField: SetField<SystemMessageStore>;
  systemMessages: SiteConfigValue<'systemMessages'> | null;
  editingSystemMessages: SiteConfigValue<'systemMessages'> | null;
  saving: boolean;
  dirty: boolean;
  hydrate: (systemMessages: SiteConfigValue<'systemMessages'>) => void;
  onChangeSystemMessage: (key: keyof SiteConfigValue<'systemMessages'>, message: string) => void;
};

const defaultState = {
  systemMessages: null,
  editingSystemMessages: null,
  saving: false,
  dirty: false,
};

export const createEditSystemMessageStoreSlice = createStoreSlice('SystemMessageStoreData', defaultState, ({ set, setField }) => ({
  setField,
  hydrate: (systemMessages) => {
    set({ systemMessages, editingSystemMessages: structuredClone(systemMessages) });
  },
  onChangeSystemMessage: (key, message) => {
    set((s) => {
      if (!s.editingSystemMessages) return s;

      const updates: Partial<SystemMessageStore> = {
        editingSystemMessages: {
          ...s.editingSystemMessages,
          [key]: message,
        },
      };

      if (message !== s.systemMessages?.[key]) updates.dirty = true;
      return { ...s, ...updates };
    });
  },
}));
