'use client';

import { MILLISECONDS_IN_DAY } from '@/util/date';
import type { UserRoleType } from '@magicschool/supabase/types';
import { useStore } from 'features/store';

export const useAuth = () => {
  const { session, ...rest } = useStore(
    ({ AuthStoreData: as }) => ({
      ...as,
    }),
    [],
  );

  return {
    ...rest,
    session,
    get supabaseUser() {
      return session;
    },
    get isSignedIn(): boolean {
      return !!session?.id;
    },
    get userId(): string {
      return session?.id ?? '';
    },
    get orgId(): string {
      return rest.userData.org_id ?? '';
    },
    get userEmail(): string {
      return rest.userData.email ?? '';
    },
    get planId(): number {
      return rest.userData.plan ?? 1;
    },
    get userRole(): UserRoleType {
      return rest.userData.user_role ?? 'teacher';
    },
    get userAttributes() {
      return rest.userAttributes ?? [];
    },
    get orgAttributes() {
      return rest.orgAttributes ?? [];
    },
    get orgToolAccessOverrides() {
      return rest.orgToolAccessOverrides ?? [];
    },
    get userToolAccessOverrides() {
      return rest.userToolAccessOverrides ?? [];
    },
    get isNew() {
      if (!rest.userData.created_at) return false;

      // Only return true if the user has been created in the last 14 days
      const isNew = new Date(rest.userData.created_at) > new Date(new Date().getTime() - 14 * MILLISECONDS_IN_DAY);

      return isNew;
    },
    get passABTest() {
      return rest.userData.serial_id % 2 === 0;
    },
  };
};
