import type { DomainSpecificConfig } from '../types';

const config: DomainSpecificConfig = {
  domains: [
    /.*\.vercel\.app/,
    /student-.*\.vercel\.app/,
    /^student-.*-magicschoolai-vercel-app\.translate\.goog/,
    /student\.magicschooldev\.com/,
  ],
  domainType: 'student',
};

export default config;
