import { IMAGE_EDITED_INTL_ID } from 'features/chat/constants';
import type { ChatMessage } from 'features/chat/types';

export function makeImageMessage(userId: string, threadId: number, resourceId: string, previousResourceId?: string): ChatMessage {
  return {
    role: 'assistant',
    user_id: userId,
    assistant_thread_id: threadId,
    content: [
      {
        type: 'image',
        id: resourceId,
        model: 'adobe-express',
        previousResourceId,
      },
    ],
  };
}

// The student table NEEDS user messages to display updates, so this will create a 'hidden' user message
export function makeUserImageMessage(userId: string, threadId: number): ChatMessage {
  return {
    role: 'user',
    user_id: userId,
    assistant_thread_id: threadId,
    content: [
      {
        type: 'image',
        text: IMAGE_EDITED_INTL_ID,
      },
    ],
  };
}

export function convertDataUrlToImageFile(dataUrl: string, filename: string) {
  const arr = dataUrl.split(',');
  const mime = arr[0].match(/:(.*?);/)?.[1];
  const bstr = atob(arr[1]);
  let n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}
