import { Button } from '@magicschool/ui/Buttons/Button';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { type ReactNode, useEffect, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { DataStepActions } from './DataStepActions';
import { Grades } from './Steps/Grades';
import { Loader } from './Steps/Loader';
import { Name } from './Steps/Name';
import { Role } from './Steps/Role';
import { Subjects } from './Steps/Subjects';
import { Welcome } from './Steps/Welcome';
export type StepActionProps = {
  back?: () => void;
  next: () => void;
  nextDisabled: boolean;
  saving: boolean;
  isNewUser: boolean;
  isLastStep?: boolean;
};
type StepVariant = 'welcome' | 'loader' | 'role' | 'grades' | 'subjects' | 'name';
type SharedProps = {
  id: StepVariant;
  body: ReactNode;
  actions: (props: StepActionProps) => ReactNode;
};
type Step = SharedProps & ({
  type: 'data';
  progress: number;
  title: string;
  icon: string;
  subheader: string;
} | {
  type: 'info';
});
const LoaderTimeout = ({
  next
}: {
  next: () => void;
}) => {
  const isMounted = useRef(false);

  // This ensures that the timeout only runs one time
  useEffect(() => {
    isMounted.current = true;
    setTimeout(() => {
      if (isMounted.current) {
        next();
      }
    }, 3000);
    return () => {
      isMounted.current = false;
    };
  }, [next]);
  return null;
};
export const steps: Step[] = [{
  id: 'welcome',
  type: 'info',
  body: <Welcome />,
  actions: ({
    next,
    isNewUser
  }) => <Button variant="contained" ordinal="secondary" onClick={next} endIcon={<KeyboardArrowRight />}>
        <FormattedMessage id={isNewUser ? 'profile-builder.welcome.button-new' : 'profile-builder.welcome.button-returning'} />
      </Button>
}, {
  id: 'loader',
  type: 'info',
  body: <Loader />,
  actions: ({
    next
  }) => <LoaderTimeout next={next} />
},
// This is for the school step that will be built out later
//
// {
//   id: 'school',
//   type: 'data',
//   title: 'What’s your school/institution affiliation? ',
//   icon: '/icon-made-for-schools.svg',
//   subheader: 'We can tailor your experience to your location and district.',
//   body: 'School body component will go here when we build out this functionality later',
//   actions: ({ next, back, nextDisabled }) => <DataStepActions next={next} back={back} nextDisabled={nextDisabled} />,
// },
{
  id: 'role',
  type: 'data',
  progress: 25,
  title: 'profile-builder.role.title',
  icon: '/icons/icon-briefcase.svg',
  subheader: 'profile-builder.role.subheader',
  body: <Role />,
  actions: ({
    next,
    nextDisabled,
    saving
  }) => <DataStepActions nextDisabled={nextDisabled} next={next} saving={saving} />
}, {
  id: 'grades',
  type: 'data',
  progress: 50,
  title: 'profile-builder.grade.title',
  icon: '/icons/icon-graduation-cap.svg',
  subheader: 'profile-builder.grade.subheader',
  body: <Grades />,
  actions: ({
    back,
    next,
    nextDisabled,
    saving
  }) => <DataStepActions back={back} nextDisabled={nextDisabled} next={next} saving={saving} />
}, {
  id: 'subjects',
  type: 'data',
  progress: 75,
  title: 'profile-builder.subject.title',
  icon: '/icons/icon-bookstack.svg',
  subheader: 'profile-builder.subject.subheader',
  body: <Subjects />,
  actions: ({
    back,
    next,
    nextDisabled,
    saving
  }) => <DataStepActions back={back} nextDisabled={nextDisabled} next={next} saving={saving} />
}, {
  id: 'name',
  type: 'data',
  progress: 100,
  title: 'profile-builder.name.title',
  icon: '/icons/icon-id-card.svg',
  subheader: 'profile-builder.name.subheader',
  body: <Name />,
  actions: ({
    back,
    next,
    nextDisabled,
    saving
  }) => <DataStepActions back={back} nextDisabled={nextDisabled} isLastStep next={next} saving={saving} />
}];