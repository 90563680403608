export const channels = {
  studentRoomChannel: (roomId: string) => `student-room-${roomId}`,
  teacherRoomChannel: (roomId: string) => `teacher-room-${roomId}`,
};

export const events = {
  toolChat: 'toolChat',
  roomState: 'roomState',
  roomStudentState: 'roomStudentState',
  roomStudentJoined: 'roomStudentJoined',
  flaggedMessage: 'flaggedMessage',
} as const;
