// There a couple circumstances where storage doesn't work
// so we're going to make it in memory when we don't have
// access to local storage

let localStorageChecked = false;
let localStorageWorking = false;

const checkLocalStorage = () => {
  try {
    // we don't check clear because that would be destructive in this case
    localStorage.setItem('test', 'value');
    const value = localStorage.getItem('test');
    localStorage.removeItem('test');
    return value === 'value';
  } catch {
    return false;
  }
};

const getMockedLocalStorage = () => {
  const map = new Map<string, string>();

  return {
    getItem: (key: string) => map.get(key) ?? null, // null is what local storage returns over undefined
    setItem: (key: string, value: string) => {
      map.set(key, value);
    },
    removeItem: (key: string) => {
      map.delete(key);
    },
    clear: () => {
      map.clear();
    },
  };
};

const mockedLocalStorage = getMockedLocalStorage();

const getStorage = () => {
  // only want to check if local storage works once
  if (!localStorageChecked) {
    localStorageWorking = checkLocalStorage();
    localStorageChecked = true;
  }

  return localStorageWorking ? localStorage : mockedLocalStorage;
};

// export an object that has the same interface as localStorage
export const storage = {
  setItem: (key: string, value: string) => getStorage().setItem(key, value),
  getItem: (key: string): string | null | undefined => getStorage().getItem(key),
  removeItem: (key: string) => getStorage().removeItem(key),
  clear: () => getStorage().clear(),
};
