import Logo from '@/components/ui-component/LogoClient';
import { useStore } from '@/features/store';
import { Box } from '@magicschool/ui/Box';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Image from 'next/image';
import { FormattedMessage } from 'react-intl';
export const Welcome = () => {
  const isNewUser = useStore(({
    ProfileBuilderStoreData: s
  }) => s.isNewUser, []);
  const theme = useTheme();
  const isFullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  return <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" gap={2} paddingX={isFullScreen ? 2 : 7} marginTop={2} height="100%" data-sentry-element="Box" data-sentry-component="Welcome" data-sentry-source-file="Welcome.tsx">
      {isNewUser ? <Logo width={144} variant="free" /> : <Image src="/logo-book.svg" alt="MagicSchool.ai Logo with book" height={100} width={318} style={{
      height: 'auto'
    }} />}
      <Typography variant="h1" fontSize={20} textAlign="center" marginTop={3} data-sentry-element="Typography" data-sentry-source-file="Welcome.tsx">
        <FormattedMessage id={isNewUser ? 'profile-builder.welcome.title-new' : 'profile-builder.welcome.title-returning'} data-sentry-element="FormattedMessage" data-sentry-source-file="Welcome.tsx" />
      </Typography>
      {isNewUser ? <>
          <Typography variant="body2" fontSize={16} textAlign="center">
            <FormattedMessage id="profile-builder.welcome.body1" values={{
          b: text => <b>{text}</b>,
          i: text => <i>{text}</i>
        }} />
          </Typography>
          <Typography variant="body2" fontSize={16} textAlign="center">
            <FormattedMessage id="profile-builder.welcome.body2" values={{
          b: text => <b>{text}</b>
        }} />
          </Typography>
          <Typography variant="body2" fontSize={16} textAlign="center">
            <FormattedMessage id="profile-builder.welcome.body3" />
          </Typography>
        </> : <Typography variant="body2" fontSize={16} textAlign="center">
          <FormattedMessage id="profile-builder.welcome.returning" />
        </Typography>}
    </Box>;
};