import { useCallback, useContext } from 'react';
import { shallow } from 'zustand/shallow';
import { useStoreWithEqualityFn } from 'zustand/traditional';
import { ZustandContext } from './contextClient';
import type { FullState } from './store';

export const useStore = <T>(selector: (store: FullState) => T, deps: unknown[]): T => {
  const context = useContext(ZustandContext);

  if (!context) {
    throw new Error(`useStore must be use within ZustandProvider`);
  }

  return useStoreWithEqualityFn(context, useCallback(selector, deps), shallow);
};
