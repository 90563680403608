import type { AuthProviderType } from '@magicschool/supabase/types';
import config from 'config/frontend';
import type { IntlShape } from 'react-intl';

export const allProviders: AuthProviderType[] = ['google', 'azure', 'email', 'edlink'];

export const passwordConstraints = (intl: IntlShape) => ({
  required: intl.formatMessage({ id: 'auth.password-required' }),
  pattern: {
    value: config.passwords.isValidRegex,
    message: intl.formatMessage({ id: 'auth.invalid-password' }),
  },
});

export const supportsEmailProvider = (providers: AuthProviderType[]) => providers.includes('email');
