import { logger } from '@magicschool/logger';
import type { PostgrestSingleResponse } from '@supabase/supabase-js';
import { SupabaseError } from './errors';

export const throwOnError: <Result>(obj: PostgrestSingleResponse<Result>) => PostgrestSingleResponse<Result> & { error: null } = (obj) => {
  if (obj.error !== null) {
    throw new SupabaseError(obj);
  }
  return obj;
};

export const dataOnSuccess: <Result>(obj: PostgrestSingleResponse<Result>) => Result = (obj) => {
  return throwOnError(obj).data;
};

/**
 * @param message a human-readable message - no need to include the error object itself
 * @returns logs an error with the passed-in message if one occurs, then returns the result without throwing an exception
 */
export const logOnError =
  <Result>(message: string) =>
  (result: PostgrestSingleResponse<Result>): PostgrestSingleResponse<Result> => {
    if (result.error) {
      logger.error(message, result.error);
    }
    return result;
  };
