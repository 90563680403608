import { subjects } from '@/features/profile/constants';
import { useStore } from '@/features/store';
import { analyticsTrack } from '@/util/amplitude';
import { Box } from '@magicschool/ui/Box';
import { Button } from '@magicschool/ui/Buttons/Button';
import { ChipMultiselect } from '@magicschool/ui/ChipMultiselect';
import { TextField } from '@magicschool/ui/TextField';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
export const Subjects = () => {
  const {
    selectedSubjects,
    customSubject,
    setField
  } = useStore(({
    ProfileBuilderStoreData: s
  }) => ({
    selectedSubjects: s.selectedSubjects,
    customSubject: s.customSubject,
    setField: s.setField
  }), []);
  const [showInput, setShowInput] = useState(customSubject !== '');
  const theme = useTheme();
  const isFullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const onShowCustomSubject = () => {
    analyticsTrack('ProfileBuilder:SubjectMissingClicked');
    setShowInput(true);
  };
  useEffect(() => {
    if (showInput) {
      const body = document.querySelector('#profile-builder');
      body?.scrollTo({
        top: body.scrollHeight,
        left: 0,
        behavior: 'smooth'
      });
    }
  }, [showInput]);
  return <>
      <Box paddingX={isFullScreen ? 0 : 2} marginBottom={2} data-sentry-element="Box" data-sentry-source-file="Subjects.tsx">
        <ChipMultiselect options={subjects} selectedOptions={selectedSubjects} setSelectedOptions={setField('selectedSubjects')} intlId={option => `subject.${option}`} data-sentry-element="ChipMultiselect" data-sentry-source-file="Subjects.tsx" />
      </Box>
      <Button ordinal="secondary" sx={{
      textDecoration: 'underline',
      textTransform: 'none'
    }} onClick={onShowCustomSubject} data-sentry-element="Button" data-sentry-source-file="Subjects.tsx">
        <FormattedMessage id="missing-subject" data-sentry-element="FormattedMessage" data-sentry-source-file="Subjects.tsx" />
      </Button>
      {showInput && <TextField label={<FormattedMessage id="profile-builder.subject.add-subject" />} variant="filled" fullWidth sx={{
      maxWidth: 320
    }} margin="normal" value={customSubject} onChange={e => setField('customSubject')(e.target.value)} />}
    </>;
};