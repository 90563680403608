import devSchoolConfig from './dev-school';
import devStudentConfig from './dev-student';
import localSchoolConfig from './local-school';
import localStudentConfig from './local-student';
import prodSchoolConfig from './prod-school';
import prodStudentConfig from './prod-student';

// Order is important, if one domain (eg. *.magicschool.ai) is a superset of another (eg. *.student.magicschool.ai)
// then the more specific one should be first
const priorityOrderedConfigs = [
  prodStudentConfig,
  prodSchoolConfig,
  devSchoolConfig, // dev school needs to be above dev student, because it is more specific
  devStudentConfig,
  localStudentConfig,
  localSchoolConfig,
];

// Nextjs runs this in the server too...
function getDomainSpecificConfig() {
  const currentConfig = priorityOrderedConfigs.find((c) => c.domains.some((domain) => domain.test(window?.location.host)));

  if (!currentConfig) {
    throw new Error(`Domain ${window?.location.host} is not included in any of the configs!`);
  }

  return currentConfig;
}

const domainSpecificConfig = process.browser ? getDomainSpecificConfig() : localSchoolConfig;
export default domainSpecificConfig;
