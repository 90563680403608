import { UserRole } from '@magicschool/supabase/types';

const roles = Object.values(UserRole);

export const isAtLeastRole = (userRole: `${UserRole}` | null, atLeastRole: UserRole | null): boolean => {
  // i think false makes sense?
  if (!userRole || !atLeastRole) return false;
  const userRoleIdx = roles.findIndex((role) => role === userRole);
  const atLeastRoleIdx = roles.findIndex((role) => role === atLeastRole);

  return userRoleIdx >= atLeastRoleIdx;
};
