import { storage } from '@/features/storage';
import { getAllCookies } from '@/util/cookies';
import type { User } from '@magicschool/supabase/types';
import type { UpdateUserRoleResponse } from 'app/api/admin/users/[id]/update_role/route';
import type { UsersSearchResponse } from 'app/api/admin/users/search/route';
import { type SetField, createStoreSlice } from 'features/store/zustand';
import toast from 'react-hot-toast';

export const roles: NonNullable<User['user_role']>[] = ['teacher', 'org_admin'] as const;

export type AdminUsersStore = {
  loading: boolean;
  users: UsersSearchResponse['users'];
  editingUser: User | null;
  changeRoleModalOpen: boolean;
  load: () => void;
  search: () => Promise<void>;
  impersonateUser: (id: string) => Promise<void>;
  openChangeRoleModal: (user: User) => void;
  changeRole: () => Promise<void>;
  setField: SetField<AdminUsersStore>;
  searchQuery: string;
  selectedRole: NonNullable<User['user_role']>;
};

const defaultState = {
  loading: true,
  editingUser: null,
  changeRoleModalOpen: false,
  selectedRole: 'teacher' as const,
  users: [],
  searchQuery: '',
};

export const createAdminUsersStoreSlice = createStoreSlice('AdminUsersStoreData', defaultState, ({ get, set, setField }) => ({
  setField,
  load: () => set({ ...defaultState, loading: false }),
  search: async () => {
    const { searchQuery } = get();
    if (searchQuery.length < 3) return;
    set({ loading: true, users: [] });

    const response = await fetch<UsersSearchResponse>(`/api/admin/users/search?email=${searchQuery}`);
    const { users } = await response.json();

    set({ loading: false, users });
  },
  impersonateUser: async (id) => {
    const originalAuthCookies = getAllCookies();
    for (const [key, value] of Object.entries(originalAuthCookies)) {
      const newCookieName = `original-${key}`;
      storage.setItem(newCookieName, value);
    }

    await fetch(`/api/admin/users/${id}/impersonate`, {
      method: 'POST',
      onSuccess: ({ router }) => {
        router.push('/tools');
        router.refresh();
      },
    });
  },
  openChangeRoleModal: (user) => {
    set({ editingUser: user, changeRoleModalOpen: true });
  },
  changeRole: async () => {
    const { editingUser, selectedRole } = get();
    if (!editingUser) return;
    await fetch<UpdateUserRoleResponse>(`/api/admin/users/${editingUser.id}/update_role`, {
      method: 'PUT',
      body: JSON.stringify({ user_role: selectedRole }),
      onSuccess: async ({ response }) => {
        const data = await response.json();
        toast.success("User's role updated successfully");
        set((state) => {
          const users = state.users.map((user) => (user.id === data.user?.id ? { ...user, ...data.user } : user));
          return { ...state, users, editingUser: null, changeRoleModalOpen: false, selectedRole: 'teacher' as const };
        });
      },
    });
  },
}));
