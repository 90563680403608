import { createStoreSlice } from '@/features/store/zustand';
import type { SiteConfigs } from '@magicschool/business-logic/site-configs';

export type SiteConfigStore = {
  siteConfigs: SiteConfigs;
};

type InitialData = {
  siteConfigs: SiteConfigs;
};

export const createSiteConfigStoreSlice = ({ siteConfigs }: InitialData) => {
  return createStoreSlice('SiteConfigStoreData', { siteConfigs }, () => ({}));
};
