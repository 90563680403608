import { UserRole } from '@magicschool/supabase/types';
import { type SetField, createStoreSlice } from 'features/store/zustand';
import type { ReactNode } from 'react';
import { isAtLeastRole } from 'util/UserRole';

export type NavItemType = {
  id?: string;
  parentId?: string;
  icon?: ReactNode;
  target?: boolean;
  external?: boolean;
  url?: string | undefined;
  altUrl?: string;
  title?: ReactNode | string;
  children?: NavItemType[];
  dataTestId?: string;
  requireAtLeastRole?: UserRole;
  requireMagicStudent?: boolean;
  requireAdminAccess?: boolean;
  disabled?: boolean;
};

export type SideBarStore = {
  setField: SetField<SideBarStore>;
  initialize: (navItems: NavItemType[], userRole: `${UserRole}`, magicStudentEnabled: boolean, adminAccess: boolean) => void;
  updateNavItems: () => void;
  checkForUserRole: (navItem: NavItemType) => boolean;
  checkForMagicStudentFF: (navItem: NavItemType) => boolean;
  checkForAdminFF: (navItem: NavItemType) => boolean;
  toggleDrawer: () => void;
  navItems: NavItemType[];
  userRole: `${UserRole}`;
  magicStudentEnabled: boolean;
  adminAccess: boolean;
  isDrawerOpen: boolean;
};

const defaultState = {
  navItems: [],
  userRole: UserRole.student,
  magicStudentEnabled: false,
  adminAccess: false,
  isDrawerOpen: false,
};

function filterNavItems(items: NavItemType[], predicates: ((item: NavItemType) => boolean)[]): NavItemType[] {
  return items.filter((item) => {
    const isItemValid = predicates.every((predicate) => predicate(item));
    if (item.children && item.children.length > 0) {
      item.children = filterNavItems(item.children, predicates);
    }
    return isItemValid || (item.children && item.children.length > 0);
  });
}

export const createSideBarStoreSlice = createStoreSlice('SideBarStoreData', defaultState, ({ get, set, setField }) => ({
  setField,
  initialize: (navItems, userRole, magicStudentEnabled, adminAccess) => {
    set({ ...defaultState, navItems, userRole, magicStudentEnabled, adminAccess });
    const { updateNavItems } = get();
    updateNavItems();
  },
  updateNavItems: () => {
    const { navItems, checkForUserRole, checkForMagicStudentFF, checkForAdminFF } = get();
    const filteredItems = filterNavItems(navItems, [checkForUserRole, checkForMagicStudentFF, checkForAdminFF]);
    set({ navItems: filteredItems });
  },
  checkForUserRole: (navItem) => {
    const { userRole } = get();
    return !navItem.requireAtLeastRole || (navItem.requireAtLeastRole && isAtLeastRole(userRole, navItem.requireAtLeastRole));
  },
  checkForMagicStudentFF: (navItem) => {
    const { magicStudentEnabled } = get();
    return !navItem.requireMagicStudent || (navItem.requireMagicStudent && magicStudentEnabled);
  },
  checkForAdminFF: (navItem) => {
    const { adminAccess } = get();
    return !navItem.requireAdminAccess || (navItem.requireAdminAccess && adminAccess);
  },
  toggleDrawer: () => set((s) => ({ isDrawerOpen: !s.isDrawerOpen })),
}));
