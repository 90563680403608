import { createBrowserClient } from '@magicschool/supabase/clients/browser';
import { SCHOOL_COOKIE_NAME } from '@magicschool/utils/constants/cookies';
import { deleteCookie, getAllCookies } from './cookies';

export const clientSupabase = createBrowserClient({
  overrideFetch: async (input: string | URL | Request, init?: RequestInit) => {
    const url = typeof input === 'string' ? new URL(input) : input instanceof Request ? new URL(input.url) : input;

    try {
      const response = await fetch(input, init);
      return response;
    } catch {
      if (url.searchParams.get('grant_type') === 'refresh_token') {
        const authCookies = Object.entries(getAllCookies()).filter((x) => x[0].startsWith(SCHOOL_COOKIE_NAME));
        for (const [name, _] of authCookies) {
          deleteCookie(name);
        }
        window.location.href = `/auth/signin?next=${encodeURIComponent(window.location.pathname)}`;
      }
      throw new Error('Failed to fetch');
    }
  },
});
// biome-ignore lint/suspicious/noExplicitAny: <explanation>
export const untypedClientSupabase = clientSupabase as ReturnType<typeof createBrowserClient<any>>;
