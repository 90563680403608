import type { FileMetadata } from '@magicschool/business-logic/tools';
import { logger } from '@magicschool/logger';
import type { User } from '@magicschool/supabase/types';
import { handle } from 'features/db/error';
import omit from 'lodash-es/omit';
import { clientSupabase, untypedClientSupabase } from 'util/supabase';
import { makeBasicRequestMessage, makeBasicResponseMessage, makeToolInputMessage, makeToolOutputMessage } from './messages';
import type { AssistantThread } from './types';

export function deleteAssistantThread(threadId: number) {
  return clientSupabase.from('assistant_threads').update({ deleted: true }).eq('id', threadId).then(handle);
}

export function bookmarkAssistantThread(threadId: number) {
  return clientSupabase.from('assistant_threads').update({ saved: true }).eq('id', threadId).select().single().then(handle);
}

export function removeAssistantThreadBookmark(threadId: number) {
  return clientSupabase.from('assistant_threads').update({ saved: false }).eq('id', threadId).select().single().then(handle);
}

export function updateAssistantThread(threadId: number, data: Partial<AssistantThread>) {
  return clientSupabase.from('assistant_threads').update(data).eq('id', threadId).select().single().then(handle);
}

export function saveToolGeneration(args: {
  model: string;
  userId: string;
  toolSlug: string;
  threadId: number;
  inputs: any;
  output: any;
  tool_config_id: string;
  generationId?: string;
}) {
  const { model, userId, toolSlug, threadId, inputs, output, tool_config_id, generationId } = args;
  const userMessage = makeToolInputMessage(inputs, userId, threadId, toolSlug);
  const assistantMessage = makeToolOutputMessage({ output, model, userId, threadId, toolSlug, tool_config_id, generationId });
  return untypedClientSupabase.from('assistant_thread_messages').insert([userMessage, assistantMessage]).then(handle);
}

export function saveChatCompletion({
  model,
  userId,
  threadId,
  input,
  output,
  files,
  tool_config_id,
  generationId,
  tool_slug,
}: {
  model: string;
  userId: string;
  threadId: number;
  input: string;
  output: string;
  files?: FileMetadata[];
  tool_config_id: string;
  generationId?: string;
  tool_slug?: string;
}) {
  try {
    const userMessage = makeBasicRequestMessage(input, userId, threadId, files);
    const assistantMessage = makeBasicResponseMessage({ tool_config_id, output, model, userId, threadId, generationId });
    return untypedClientSupabase
      .from('assistant_thread_messages')
      .insert([omit(userMessage, ['id']), omit(assistantMessage, ['id'])])
      .then(handle);
  } catch (e) {
    logger.error('DISAPPEARING GENERATION BUG', {
      note: 'Error saving chat completion, generation has succeeded but message was not saved to the db',
      threadId,
      output,
      input,
      model,
      tool_slug,
      userId,
      e,
    });
    throw e;
  }
}

export function saveChatRequest(userId: string, threadId: number, input: string, files?: FileMetadata[]) {
  const userMessage = makeBasicRequestMessage(input, userId, threadId, files);
  return untypedClientSupabase
    .from('assistant_thread_messages')
    .insert([omit(userMessage, ['id'])])
    .then(handle);
}

export function saveChatResponse({
  model,
  userId,
  threadId,
  output,
  tool_config_id,
  generationId,
  tool_slug,
}: { model: string; userId: string; threadId: number; output: string; tool_config_id: string; tool_slug?: string; generationId?: string }) {
  try {
    const assistantMessage = makeBasicResponseMessage({ tool_config_id, output, model, userId, threadId, generationId });
    return untypedClientSupabase
      .from('assistant_thread_messages')
      .insert([omit(assistantMessage, ['id'])])
      .then(handle);
  } catch (e) {
    logger.error('DISAPPEARING GENERATION BUG', {
      note: 'Error saving chat response, generation has succeeded but message was not saved to the db',
      threadId,
      output,
      model,
      userId,
      tool_slug,
      e,
    });
    throw e;
  }
}

// TODO: auth store
export async function upsertAssistantThreadLink(assistantThreadId: AssistantThread['id'], user: User, accessType = 'private') {
  return handle(
    await untypedClientSupabase
      .from('assistant_thread_links')
      .upsert(
        {
          assistant_thread_id: assistantThreadId,
          user_id: user.id,
          org_id: user.org_id,
          access_type: accessType,
        },
        { onConflict: 'assistant_thread_id', ignoreDuplicates: false },
      )
      .select()
      .single(),
  );
}

export async function getAssistantThreadLinkByThreadId(id: number) {
  return handle(await clientSupabase.from('assistant_thread_links').select().eq('assistant_thread_id', id).single());
}
