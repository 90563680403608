import { storage } from '@/features/storage';
import { deleteCookie, getCookieValue, setCookie } from '@/util/cookies';
import { MILLISECONDS_IN_YEAR } from '@/util/date';
import { clientSupabase } from '@/util/supabase';

export const handleSignOut = async (args?: { deleted: boolean }) => {
  const isImpersonating = getCookieValue('impersonating') === 'true';
  try {
    await clientSupabase.auth.signOut();

    if (isImpersonating) {
      const originalAuthCookies = Object.entries(localStorage).filter(([key]) => key.startsWith('original-'));
      for (const [key, value] of originalAuthCookies) {
        setCookie(key.replace('original-', ''), value, { expires: new Date(Date.now() + MILLISECONDS_IN_YEAR) });
        storage.removeItem(key);
      }
      deleteCookie('impersonating');
    }
  } finally {
    if (!isImpersonating) {
      window.location.href = `/auth/signin?next=${window.location.pathname}${args?.deleted ? '&deleted=true' : ''}`;
    } else {
      window.location.reload();
    }
  }
};
