import merge from 'lodash-es/merge';
import baseConfig from './base';
import domainSpecificConfig from './domains';
import { createPublicSharedConfigFromEnv } from './env';

// Create the public shared config from the environment variables
const publicSharedConfig = createPublicSharedConfigFromEnv();

const config = merge(baseConfig, publicSharedConfig, domainSpecificConfig);

// The or is just for typing
export default config;
