'use client';

import { useAuth } from '@/hooks/useAuth';
import { subscriptionPlans } from '@magicschool/supabase/user/utils';
import { Box } from '@magicschool/ui/Box';
import useFeatureFlags from 'hooks/useFeatureFlags';
import Image from 'next/image';
import { usePathname } from 'next/navigation';
const LOGO_SIZES = {
  free: {
    height: 52,
    width: 111
  },
  student: {
    height: 52,
    width: 120
  },
  plus: {
    height: 80,
    width: 111
  },
  admin: {
    height: 80,
    width: 111
  },
  enterprise: {
    height: 80,
    width: 111
  }
};
export type LogoType = keyof typeof LOGO_SIZES;
const Logo = ({
  variant,
  width,
  height,
  angled = true
}: {
  variant?: LogoType;
  width?: number;
  height?: number;
  angled?: boolean;
}) => {
  const {
    userData,
    orgId
  } = useAuth();
  const {
    trialAccess
  } = useFeatureFlags();
  const pathname = usePathname();
  const isPlusUser = userData?.plan === subscriptionPlans.PLUS || trialAccess;
  const isEnterpriseUser = Boolean(orgId);
  const getLogo = () => {
    if (variant) return variant;
    if (pathname?.startsWith('/admin')) return 'admin';
    if (isEnterpriseUser) return 'enterprise';
    if (isPlusUser) return 'plus';
    return 'free';
  };
  const logoName = getLogo();
  const sizingProps = {
    width: width ?? LOGO_SIZES[logoName].width,
    height: height ?? LOGO_SIZES[logoName].height
  };
  return <Box display="flex" data-sentry-element="Box" data-sentry-component="Logo" data-sentry-source-file="LogoClient.tsx">
      <Image priority src={`/assets/images/logos/${angled ? 'angled' : 'straight'}/${logoName}.png`} alt="MagicSchool.ai Logo" {...sizingProps} style={{
      height: 'auto'
    }} data-sentry-element="Image" data-sentry-source-file="LogoClient.tsx" />
    </Box>;
};
export default Logo;