import { validateFileType } from 'file-type-checker';
import type { MimeType } from './types';

// Gets the mime type using the 'magic number' in the header of the file. Only works for binary files
export function checkIfFileHasValidMimeType(file: File, validMimeTypes: MimeType[]): Promise<boolean> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      if (!reader.result) {
        reject('File could not be read');
      }

      resolve(validateFileType(new Uint8Array(reader.result as ArrayBuffer), validMimeTypes));
    };

    reader.readAsArrayBuffer(file);
  });
}
