'use client';

import type { AppRouterInstance } from 'next/dist/shared/lib/app-router-context.shared-runtime';
import type { CustomFetchOptions, ExtendedResponse, InterceptorConfig, MonkeyFetchFunc } from './types';

type ErrorHandlers = (InterceptorConfig['responseErrorHandlers'] | undefined)[];
type ErrorHandlerKey = keyof NonNullable<InterceptorConfig['responseErrorHandlers']>;

const handlerLookup: Record<number, ErrorHandlerKey> = {
  400: 'badRequest',
  401: 'unauthorized',
  403: 'forbidden',
  404: 'notFound',
  409: 'conflict',
};

// Handle specific response errors
const handleResponseError = async (
  router: AppRouterInstance,
  handlers: ErrorHandlers,
  response: ExtendedResponse | null,
): Promise<boolean> => {
  for (const handlerGroup of handlers) {
    if (!handlerGroup) {
      continue;
    }

    const handlerKey = response === null ? 'unknown' : response.status in handlerLookup ? handlerLookup[response.status] : 'unknown';
    const handler = handlerGroup[handlerKey];
    if (handler) {
      // Response is already checked for null, so we can safely assert it here
      // Don't know why TS is complaining
      const result = await handler({ response: response!, router });

      if (!result || (typeof result === 'object' && result.shortCircuit)) {
        return true;
      }
    }
  }
  return false;
};

export function registerMonkeyFetch(router: AppRouterInstance, interceptors: InterceptorConfig[] = []) {
  const originalFetch: MonkeyFetchFunc = window.fetch;

  async function monkeyFetch<T = unknown>(input: RequestInfo | URL, init?: CustomFetchOptions<T>): Promise<ExtendedResponse<T>> {
    let modifiedInput = input;
    let modifiedInit = { ...init };

    const url = input instanceof URL ? input.href : typeof input === 'string' ? input : input.url;

    const matchedInterceptors = interceptors.filter(({ matcher }) => {
      const matchers = Array.isArray(matcher) ? matcher : [matcher];
      return matchers.some((regex) => regex.test(url));
    });

    // If no interceptors match, just perform the original fetch
    if (matchedInterceptors.length === 0) {
      return originalFetch(input, init);
    }

    const requestInterceptors = matchedInterceptors.map(({ requestInterceptor }) => requestInterceptor).filter(Boolean);

    const responseInterceptors = matchedInterceptors.map(({ responseInterceptor }) => responseInterceptor).filter(Boolean);

    const requestErrorHandlers = matchedInterceptors.map(({ requestErrorHandler }) => requestErrorHandler).filter(Boolean);

    const globalResponseErrorHandlers = matchedInterceptors.map(({ responseErrorHandlers }) => responseErrorHandlers).filter(Boolean);

    const localResponseErrorHandlers = init?.responseErrorHandlers ? [init.responseErrorHandlers] : [];

    const responseErrorHandlers = [...localResponseErrorHandlers, ...globalResponseErrorHandlers];

    const onSuccessHandler = init?.onSuccess;

    // Apply request interceptors
    for (const interceptor of requestInterceptors) {
      try {
        if (interceptor) {
          const result = await interceptor(url, modifiedInit);
          modifiedInput = result.url;
          modifiedInit = result.options;
        }
      } catch (error) {
        for (const handler of requestErrorHandlers) {
          handler?.(error as Error, modifiedInput, modifiedInit);
        }
      }
    }

    try {
      // Perform the fetch
      let response = await originalFetch(modifiedInput, modifiedInit);

      // Apply response interceptors
      for (const interceptor of responseInterceptors) {
        if (interceptor) {
          const clonedResponse = response.clone();
          response = await interceptor(clonedResponse);
        }
      }

      const clonedResponse = response.clone();
      if (!response.ok) {
        if (await handleResponseError(router, responseErrorHandlers, clonedResponse)) return response;
      } else if (onSuccessHandler) {
        await onSuccessHandler({ router, response: clonedResponse });
      }

      return response;
    } catch (error) {
      console.error('Error in monkeyFetch:', error);
      if (await handleResponseError(router, responseErrorHandlers, null)) {
        throw error;
      }
      throw error;
    }
  }

  // Monkey-patch the global fetch function if on client side
  if (typeof window !== 'undefined') {
    window.fetch = monkeyFetch as typeof window.fetch;
  }

  return { unregister: () => (window.fetch = originalFetch as typeof window.fetch) };
}
