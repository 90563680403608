import type { RoomTeachersResponse } from 'app/api/rooms/[id]/room_teachers/route';
import { type SetField, createStoreSlice } from 'features/store/zustand';

export type RoomCoTeacherStore = {
  setField: SetField<RoomCoTeacherStore>;
  loading: boolean;
  error: string;
  joinCode: string;
  load: (joinCode: string) => void;
  joinRoom: () => Promise<void>;
};

const defaultState = {
  loading: true,
  joinCode: '',
  error: '',
};

export const createRoomJoinStoreSlice = createStoreSlice('RoomCoTeacherStoreData', defaultState, ({ set, get, setField }) => ({
  setField,
  load: (joinCode) => {
    set({ joinCode });

    if (joinCode) {
      get().joinRoom();
    } else {
      set({ loading: false });
    }
  },
  joinRoom: async () => {
    set({ loading: true, error: '' });
    await fetch<RoomTeachersResponse>(`/api/rooms/join`, {
      method: 'POST',
      body: JSON.stringify({ joinCode: get().joinCode }),
      onSuccess: async ({ response, router }) => {
        const { roomId } = await response.json();
        // broadcastCoteacherJoinedRoom(data.room_id, data.id); someday react to this realtime??
        router.push(`/rooms/${roomId}`);
      },
      responseErrorHandlers: {
        conflict: async ({ response, router }) => {
          const data = await response.json();
          router.push(`/rooms/${data.roomId}`);
        },
        notFound: ({ router }) => {
          set({ loading: false, error: 'Room code not found!' });
          // if something was wrong in the url we don't want to lock them into that
          router.push(`/rooms/coTeacher`);
        },
        badRequest: ({ router }) => {
          set({ loading: false, error: 'Room code not found!' });
          // if something was wrong in the url we don't want to lock them into that
          router.push(`/rooms/coTeacher`);
        },
      },
    });
  },
}));
