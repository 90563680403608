export function rand(n = 1) {
  return n * Math.random();
}

export const getMaxRandomInt = () => Math.floor(Number.MAX_SAFE_INTEGER * Math.random());

/**
 * Clamp a value between a min and max value
 */
export const clampValue = (value: number, min: number, max: number) => Math.min(max, Math.max(min, value));
