import type { GetRoomResponse, LiteRoom } from '@/app/api/rooms/utils';
import type { Room, RoomState } from '@magicschool/supabase/types';
import { type SetField, createStoreSlice } from 'features/store/zustand';
import toast from 'react-hot-toast';
import type { IntlShape } from 'react-intl';

type StateFilter = keyof typeof RoomState | 'all';
export type RoomListStore = {
  loading: boolean;
  rooms: LiteRoom[];
  stateFilter: StateFilter;
  load: () => Promise<void>;
  addRoom: (room: LiteRoom) => void;
  updateRoomInList: (updatedRoom: Room, intl: IntlShape, successKey: string, broadcastStateChange?: boolean) => Promise<void>;
  setField: SetField<RoomListStore>;
};

const defaultState = {
  loading: true,
  rooms: [],
  stateFilter: 'active' as StateFilter,
};

export const createRoomListStoreSlice = createStoreSlice('RoomListStoreData', defaultState, ({ set, get, setField, getFull }) => ({
  setField,
  load: async () => {
    set({ ...defaultState, loading: true });
    const response = await fetch<GetRoomResponse>(`/api/rooms`);
    const rooms = await response.json();
    set({ loading: false, rooms });
  },
  addRoom: (room: LiteRoom) => set((state) => ({ rooms: [...state.rooms, room] })),
  updateRoomInList: async (updatedRoom: Room, intl: IntlShape, successKey: string, broadcastStateChange = true) => {
    const rooms = get().rooms;
    const onUpdate = (room: Room) => {
      setField('rooms')(rooms.map((r) => (r.id !== room.id ? r : { ...r, room: { ...r.room, ...room } })));
      toast.success(intl.formatMessage({ id: successKey }), { duration: 4000 });
    };
    await getFull().RoomActionsStoreData.updateRoom(updatedRoom, onUpdate, intl, broadcastStateChange);
  },
}));
