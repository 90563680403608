import type { RoomState } from '@magicschool/supabase/types';
import type { OrgRoomsResponse } from 'app/api/o/rooms/route';
import { type SetField, createStoreSlice } from 'features/store/zustand';
import type { AppRouterInstance } from 'next/dist/shared/lib/app-router-context.shared-runtime';
import toast from 'react-hot-toast';

type StateFilter = keyof typeof RoomState | 'all' | 'withAlerts' | 'administrated';
export type OrgRoomsStore = {
  loading: boolean;
  setField: SetField<OrgRoomsStore>;
  orgUser: OrgRoomsResponse['orgUser'];
  roomsSummary: OrgRoomsResponse['roomsSummary'];
  rooms: OrgRoomsResponse['rooms'];
  stateFilter: StateFilter;
  load: () => Promise<void>;
  loadRoom: (roomId: string, router: AppRouterInstance) => Promise<void>;
};

const defaultState = {
  orgUser: null,
  roomsSummary: {
    activeCount: 0,
    pausedCount: 0,
    lockedCount: 0,
    administratedCount: 0,
    totalStudentCount: 0,
    totalRoomCount: 0,
    totalAlertCount: 0,
  },
  rooms: [],
  loading: true,
  stateFilter: 'all' as StateFilter,
};

export const createOrgRoomsStoreSlice = createStoreSlice('OrgRoomsStoreData', defaultState, ({ set, get, setField }) => ({
  setField,
  load: async () => {
    set({ ...defaultState, loading: true });
    await fetch<OrgRoomsResponse>(`/api/o/rooms`, {
      onSuccess: async ({ response }) => {
        const data = await response.json();
        set({ orgUser: data.orgUser, rooms: data?.rooms ?? [], roomsSummary: data.roomsSummary, loading: false });
      },
      responseErrorHandlers: {
        badRequest: () => {
          set({ loading: false, rooms: [] });
          toast.error('Failed to load rooms');
        },
      },
    });
  },
  loadRoom: async (roomId, router) => {
    set({ loading: true });
    const room = get().rooms.find((r) => r.id === roomId);
    if (room?.administrated) {
      router.push(`/o/rooms/${roomId}`);
    } else {
      await fetch(`/api/o/rooms/${roomId}`, {
        method: 'PUT',
        onSuccess: ({ router }) => {
          router.push(`/o/rooms/${roomId}`);
        },
      });
    }
  },
}));
