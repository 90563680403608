import type { TosUpdateWindow } from '@magicschool/business-logic/site-configs';
import { logger } from '@magicschool/logger';
import type { TosUpdateWindowResponse } from 'app/api/tos_updates/route';
import { type SetField, createStoreSlice } from 'features/store/zustand';
import { SECONDS_IN_HOUR } from 'util/date';

export type ToSBannerStore = {
  setField: SetField<ToSBannerStore>;
  activeBanner: TosUpdateWindow | null;
  showBanner: boolean;
  currentWindowKey: string | null;
  load: () => void;
  markBannerAcked: (windowKey: string | null) => Promise<void>;
  closeBanner: () => Promise<void>;
  handleUnload: () => Promise<boolean>;
};

const defaultState = {
  currentWindowKey: null,
  showBanner: false,
  activeBanner: null,
};

export const createToSBannerStoreSlice = createStoreSlice('ToSBannerStoreData', defaultState, ({ set, get, setField }) => ({
  setField,
  load: async () => {
    try {
      await fetch<TosUpdateWindowResponse>('/api/tos_updates', {
        next: { revalidate: SECONDS_IN_HOUR }, // break the cache every hour
        responseErrorHandlers: {
          notFound: () => {
            logger.error('Error fetching tos updates, /api/tos_updates not found.');
          },
          unauthorized: () => {
            logger.error('Error fetching tos updates, unauthorized.');
          },
        },
        onSuccess: async ({ response }) => {
          const tosUpdates = await response.json();
          if (tosUpdates.length >= 1) {
            set({ showBanner: true });
            // we could have multiple ToS updates we explicitly need to get ack'd but one at a time, please.
            set({ currentWindowKey: tosUpdates[0].windowKey });
            set({ activeBanner: tosUpdates[0] });
          } else {
            set({ showBanner: false });
          }
        },
      });
    } catch (err) {
      // This might spam the logs if there is an error because of how often this might get called
      // but once we get alerting on, it will be something we _will_ want to spam.
      logger.error('Error fetching tos updates, default to show banner!', err);
    }
  },
  markBannerAcked: async (windowKey: string | null) => {
    // i think it's just a local rebuild/refresh problem but sometimes it's null
    if (windowKey) {
      await fetch(`/api/tos_updates`, {
        method: 'POST',
        keepalive: true,
        body: JSON.stringify({ name: `tosUpdate_${windowKey}`, value: 'true' }),
      });
    } else {
      logger.warn('windowKey is null, not marking banner acked', get().activeBanner);
    }
  },
  handleUnload: async () => {
    const { markBannerAcked, showBanner, currentWindowKey } = get();
    if (!showBanner) return Promise.resolve(true);
    await markBannerAcked(currentWindowKey);
    const time = Date.now();
    // future me, if you're reading this, i'm sorry. (that was generated by co-pilot - not bad!)
    // but seriously. without this, the beforeUnload won't ack,even with keepalive
    while (Date.now() - time < 500) {}
    return Promise.resolve(true);
  },
  closeBanner: async () => {
    const { markBannerAcked, handleUnload, currentWindowKey } = get();
    set({ showBanner: false });
    window.removeEventListener('beforeunload', handleUnload, true);
    await markBannerAcked(currentWindowKey);
  },
}));
