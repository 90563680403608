import type { ExplicitToolConfig, SafeToolInfo, ToolWithPublishedConfigAndCategories } from '@magicschool/business-logic/tools';
import type { LlmModel } from '@magicschool/supabase/types';

export const emptyAdminToolDetails = (): ToolWithPublishedConfigAndCategories => ({
  tool: emptyTool(),
  published_config: emptyToolConfig(),
  categories: [],
  model: emptyModel(),
});

export const emptySafeToolDetails = (): SafeToolInfo => ({
  tool: emptyTool(),
  categories: [],
  fields: [],
  modelSlug: '',
});

export const emptyTool = () => ({
  id: '',
  title: '',
  description: '',
  icon: '',
  priority: 0,
  roles: [],
  created_at: null,
  published_config_id: '',
  is_hidden: false,
  is_new: false,
  slug: '',
  tags: [],
  updated_at: null,
  is_hot: false,
});

export const emptyToolConfig = (): ExplicitToolConfig => ({
  archived: false,
  created_at: '',
  fields: [],
  frequency_penalty: 0,
  id: '',
  max_length: 0,
  model_id: '',
  name: '',
  presence_penalty: 0,
  prompt: '',
  publish_date: null,
  system_message: '',
  temperature: 0,
  tool_id: '',
  top_p: 0,
  full_model: emptyModel(),
});

export const emptyModel = (): LlmModel => ({
  id: '',
  slug: '',
  created_at: '',
  label: '',
  provider: 'openai',
});

export interface Content {
  isBullet: boolean;
  text: string;
  indentLevel: number;
}

export interface Slide {
  objectId?: string;
  title: string;
  content: Content[];
}

export interface Presentation {
  slides: Slide[];
  title: string;
  subtitle: string;
}

export interface PresentationJson {
  presentation: Presentation;
}

export interface InsertTextRequest {
  insertText: {
    objectId: string;
    text: string;
  };
}

export interface CreateSlideRequest {
  createSlide: {
    slideLayoutReference: {
      predefinedLayout: string;
    };
    placeholderIdMappings: {
      layoutPlaceholder: {
        type: string;
        index: number;
      };
      objectId: string;
    }[];
  };
}

export interface BulletRequest {
  createParagraphBullets: {
    objectId: string;
    textRange: {
      type: string;
      startIndex: number;
      endIndex: number;
    };
    bulletPreset: string;
  };
}

export interface InsertTextRequest {
  insertText: {
    objectId: string;
    text: string;
  };
}

export type SlideRequest = CreateSlideRequest | InsertTextRequest | BulletRequest;
