import { createStore } from 'zustand';
import type { StateCreator, StoreApi } from 'zustand';
import type { FullState } from './store';

export type SetField<T> = <K extends keyof T>(key: K) => (value: T[K]) => void;

// we want to essentially ensure the root store always has a consistent shape
type RestrictedState = Record<string, unknown>;
export type CentralizedStore<S extends RestrictedState> = StateCreator<S>;

export const createStrictStore = <S extends RestrictedState>(stateCreator: CentralizedStore<S>) => {
  return createStore<S>()(stateCreator);
};

const createSlice = <SliceKey extends keyof FullState>(
  sliceKey: SliceKey,
  { getFull, setFull }: { getFull: StoreApi<FullState>['getState']; setFull: StoreApi<FullState>['setState'] },
) => {
  const get: StoreApi<FullState[SliceKey]>['getState'] = () => getFull()[sliceKey];
  const set: StoreApi<FullState[SliceKey]>['setState'] = (properties, replace = false) => {
    setFull((state: FullState) => {
      const updates = typeof properties === 'function' ? properties(state[sliceKey]) : properties;
      return {
        [sliceKey]: {
          ...(replace ? {} : state[sliceKey]),
          ...updates,
        },
      };
    });
  };

  const setField: SetField<FullState[SliceKey]> = (key) => (value) => set({ [key]: value } as any); // just trust me y'all
  return { set, get, setField };
};

export const createStoreSlice =
  <K extends keyof FullState, DefaultSliceState extends Partial<FullState[K]>>(
    key: K,
    defaultState: DefaultSliceState,
    create: (createArgs: {
      getFull: StoreApi<FullState>['getState'];
      setFull: StoreApi<FullState>['setState'];
      get: StoreApi<FullState[K]>['getState'];
      set: StoreApi<FullState[K]>['setState'];
      setField: SetField<FullState[K]>;
    }) => Omit<FullState[K], keyof DefaultSliceState>,
  ): ((_: {
    getFull: StoreApi<FullState>['getState'];
    setFull: StoreApi<FullState>['setState'];
  }) => DefaultSliceState & Omit<FullState[K], keyof DefaultSliceState>) =>
  ({ getFull, setFull }) => ({
    ...defaultState,
    ...create({ getFull, setFull, ...createSlice(key, { getFull, setFull }) }),
  });
