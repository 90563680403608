'use client';

import { logger } from '@magicschool/logger';
import { SCHOOL_COOKIE_NAME } from '@magicschool/utils/constants/cookies';
import { createBrowserClient as supabaseCreateBrowserClient } from '@supabase/ssr';
import type { SupabaseClient, SupabaseClientOptions } from '@supabase/supabase-js';
import type { Database } from '../../types/db';

type BrowserClientOptions<TDB extends Database> = {
  overrideFetch?: NonNullable<SupabaseClientOptions<TDB>['global']>['fetch'];
};

export const createBrowserClient = <TDB extends Database>({ overrideFetch }: BrowserClientOptions<TDB> = {}): SupabaseClient<TDB> => {
  const supabaseUrl = process.env.NEXT_PUBLIC_SUPABASE_URL ?? 'http://localhost:54321';
  const supabaseKey = process.env.NEXT_PUBLIC_SUPABASE_ANON_KEY ?? 'fake-supabase-key';

  return supabaseCreateBrowserClient<TDB>(supabaseUrl, supabaseKey, {
    cookieOptions: {
      name: SCHOOL_COOKIE_NAME,
      sameSite: 'none', // This setting allows the cookie to be sent in cross-site requests (e.g. Edlink/Canvas) - default is 'lax'
      secure: true, // ensures that the cookie is only sent over HTTPS connections (note: must be true when sameSite === 'none')
    },
    global: {
      fetch: (input: string | URL | Request, init?: RequestInit) => {
        try {
          logger.debug('Supabase fetch', { input, init });
          if (overrideFetch) return overrideFetch(input, init);
          return fetch(input, init);
        } catch (error) {
          logger.error('Supabase fetch error', { error });
          throw error;
        }
      },
    },
  });
};
