import type { JoinRoomResponse } from 'app/api/s/rooms/join/route';
import { type SetField, createStoreSlice } from 'features/store/zustand';
import { onJoinRoomSuccess } from './actions';

type JoinAction = (joinCode: string, name: string, roomStudentId?: string) => Promise<void>;

export type JoinStore = {
  setField: SetField<JoinStore>;
  loading: boolean;
  error: string;
  joinCode: string;
  name: string;
  login: JoinAction;
  autoJoin: JoinAction;
};

const defaultState = {
  loading: true,
  joinCode: '',
  name: '',
  error: '',
};

export const createJoinStoreSlice = createStoreSlice('JoinStoreData', defaultState, ({ set, get, setField }) => ({
  setField,
  login: async (joinCode, name, roomStudentId) => {
    set({ loading: true, error: '' });
    const body = roomStudentId ? { joinCode, roomStudentId } : { joinCode, name };
    await fetch<JoinRoomResponse>(`/api/s/rooms/join`, {
      method: 'POST',
      body: JSON.stringify(body),
      onSuccess: async ({ response, router }) => {
        const data = await response.json();
        await onJoinRoomSuccess(data.room_id, data.id);
        router.push(`/s/rooms/${data.room_id}/tools`);
      },
      responseErrorHandlers: {
        notFound: async ({ response, router }) => {
          const responseBody = await response.json<{ error: string }>();
          const error = responseBody.error === 'Record could not be found' ? 'join.room-code-not-found' : responseBody.error;
          set({ loading: false, error });
          router.push(`/s/join`);
        },
        badRequest: async ({ response, router }) => {
          const responseBody = await response.json<{ error: string }>();
          let error: string;
          if (responseBody.error.startsWith('supabase error:')) {
            error = roomStudentId ? 'join.error-rejoining-room' : 'join.room-code-not-found';
          } else {
            error = responseBody.error;
          }

          set({ loading: false, error });
          router.push(`/s/join`);
        },
        forbidden: async ({ response }) => {
          const result = await response.json<{ error: string }>();
          set({ loading: false, error: result.error });
        },
      },
    });
  },
  autoJoin: async (joinCode, roomStudentId) => {
    const { login } = get();
    await login(joinCode, '', roomStudentId);
  },
}));
