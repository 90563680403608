export const MILLISECONDS_IN_SECOND = 1000;
export const SECONDS_IN_MINUTE = 60;
export const MINUTES_IN_HOUR = 60;
export const HOURS_IN_DAY = 24;
export const DAYS_IN_WEEK = 7;
export const MONTHS_IN_YEAR = 12;
export const APPROX_DAYS_IN_MONTH = 30;
export const MILLISECONDS_IN_MINUTE = MILLISECONDS_IN_SECOND * SECONDS_IN_MINUTE;
export const SECONDS_IN_HOUR = SECONDS_IN_MINUTE * MINUTES_IN_HOUR;
export const SECONDS_IN_DAY = SECONDS_IN_MINUTE * MINUTES_IN_HOUR * HOURS_IN_DAY;
export const SECONDS_IN_MONTH = SECONDS_IN_MINUTE * MINUTES_IN_HOUR * HOURS_IN_DAY * APPROX_DAYS_IN_MONTH;
export const SECONDS_IN_YEAR = SECONDS_IN_DAY * 365;
export const MILLISECONDS_IN_HOUR = MILLISECONDS_IN_SECOND * SECONDS_IN_MINUTE * MINUTES_IN_HOUR;
export const MILLISECONDS_IN_DAY = MILLISECONDS_IN_SECOND * SECONDS_IN_MINUTE * MINUTES_IN_HOUR * HOURS_IN_DAY;
export const MILLISECONDS_IN_YEAR = MILLISECONDS_IN_DAY * 365;

export function decomposeDate(date: Date) {
  return {
    second: date.getSeconds(),
    minute: date.getMinutes(),
    hour: date.getHours(),
    day: date.getDate(),
    month: date.getMonth() + 1,
    year: date.getFullYear(),
  };
}

export function formatDateAsReadableString(date: Date) {
  const monthNames = ['Jan.', 'Feb.', 'Mar.', 'Apr.', 'May', 'Jun.', 'Jul.', 'Aug.', 'Sep.', 'Oct.', 'Nov.', 'Dec.'];
  const day = date.getDate();
  const monthIndex = date.getMonth();
  const year = date.getFullYear();

  // Determine the appropriate ordinal suffix
  const j = day % 10,
    k = day % 100;
  let suffix = 'th';
  if (j === 1 && k !== 11) {
    suffix = 'st';
  } else if (j === 2 && k !== 12) {
    suffix = 'nd';
  } else if (j === 3 && k !== 13) {
    suffix = 'rd';
  }

  return `${monthNames[monthIndex]} ${day}${suffix}, ${year}`;
}
export const renderDate = (locale: string, timestamp: string | undefined | null) => {
  if (!timestamp) return '';
  const date = new Date(timestamp);
  const now = new Date();
  const diff = (now.getTime() - date.getTime()) / (1000 * 60 * 60 * 24);

  const formatOptions: Intl.DateTimeFormatOptions = {
    month: 'short',
    day: 'numeric',
  };
  if (diff > 364) {
    formatOptions.year = 'numeric';
  } else {
    formatOptions.hour = 'numeric';
    formatOptions.minute = 'numeric';
  }

  const formatter = new Intl.DateTimeFormat(locale, formatOptions);

  return formatter.format(date);
};

export const renderMDY = (locale: string, timestamp: string | undefined | null) => {
  if (!timestamp) return '';
  const date = new Date(timestamp);

  const formatter = new Intl.DateTimeFormat(locale, {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  });

  return formatter.format(date);
};

/**
 * that's DayofWeek Month Day Year Time to you
 * @param locale
 * @param timestamp
 * @returns nicely localised, formatted date time string
 */
export const renderDoWMDYT = (locale: string, timestamp: string | undefined | null) => {
  if (!timestamp) return '';
  const date = new Date(timestamp);

  const formatter = new Intl.DateTimeFormat(locale, {
    weekday: 'short',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    timeZoneName: 'shortGeneric',
  });

  return formatter.format(date);
};

export const monthsFromToday = (months: number): number => {
  const date = new Date();
  date.setMonth(date.getMonth() + months);
  return date.getTime();
};

export function formatTimestamp(timestamp: number | null) {
  if (!timestamp) return null;
  return new Date(timestamp * MILLISECONDS_IN_SECOND).toISOString();
}
